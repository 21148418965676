import "./Onboarding.scss";

import cx from "classnames";

import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Header } from "@/components/Header/Header";
import { BaseLayout } from "@/components/Layout/BaseLayout";
import { Button, ButtonType } from "@/components/Button/Button";
import { OnboardingSlideItem } from "./OnboardingSlideItem";
import {
  IconOnboarding1,
  IconOnboarding2,
  IconOnboarding3,
  IconOnboarding4,
  IconOnboarding5,
  IconOnboarding6,
  IconOnboarding7,
  IconOnboardingMobile1,
  IconOnboardingMobile2,
  IconOnboardingMobile6,
  IconOnboardingMobile7,
} from "@/components/Icons/Onboarding";
import { useAppDispatch, useAppSelector } from "@/hooks/store.hook";
import { shallowEqual } from "react-redux";
import { setOnboarding } from "@/store/reducers/setting.reducer";
import { PageSlider } from "@/components/PageSlider/PageSlider";

export const OnBoardingPage = () => {
  const dispatch = useAppDispatch();
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const className =
    "w-full h-full max-w-[240px] xs:max-w-[280px] sm:max-w-[320px] md:max-w-[400px] max-h-[240px] xs:max-h-[280px] sm:max-h-[320px] md:max-h-[400px]";

  const items = [
    {
      image: (
        <>
          <IconOnboardingMobile1 className={cx(className, "block sm:hidden")} />
          <IconOnboarding1 className={cx(className, "hidden sm:block")} />
        </>
      ),
      text: t("onboarding_align_and_center", "Align and center your face and shoulders within the markers."),
    },
    {
      image: (
        <>
          <IconOnboardingMobile2 className={cx(className, "block sm:hidden")} />
          <IconOnboarding2 className={cx(className, "hidden sm:block")} />
        </>
      ),
      text: t("onboarding_hold_device_steady", "Hold your device steady."),
    },
    {
      image: <IconOnboarding3 className={cx(className)} />,
      text: t("onboarding_be_comfortably_seated", "Be comfortably seated."),
    },
    {
      image: <IconOnboarding4 className={cx(className)} />,
      text: t("onboarding_well_lit_face", "Make sure your face is well lit and the light is evenly spread on your face."),
    },
    {
      image: <IconOnboarding5 className={cx(className)} />,
      text: t("onboarding_do_not_move_or_talk", "Do not move or talk."),
    },
    {
      image: (
        <>
          <IconOnboardingMobile6 className={cx(className, "block sm:hidden")} />
          <IconOnboarding6 className={cx(className, "hidden sm:block")} />
        </>
      ),
      text: t("onboarding_measurement_interrupted", "If a measurement gets interrupted the timer restarts."),
    },
    {
      image: (
        <>
          <IconOnboardingMobile7 className={cx(className, "block sm:hidden")} />
          <IconOnboarding7 className={cx(className, "hidden sm:block")} />
        </>
      ),
      text: t("onboarding_press_record_at_bottom", "Press record at the bottom of the screen to start a measurement."),
      customComponent: (
        <Button
          text={t("start_a_measurement", "Start measurement")}
          type={ButtonType.Accent}
          textStyles={{ textTransform: "uppercase" }}
          className="w-[200px] sm:w-[320px] sm:px-[32px]"
          onClick={() => {
            localStorage.setItem("onboarding", Date.now().toString());
            dispatch(setOnboarding(false));
            if (setting.pluginSettings?.skip_welcome && setting.onboarding) {
              navigate("/measurement/do", { replace: true, state: { from: location.pathname } });
            } else {
              navigate(-1);
            }
          }}
        />
      ),
    },
  ];

  const __onSkip = () => {
    setActiveSlide(items.length - 1);
  };

  return (
    <BaseLayout>
      <Header hideBackButton={setting.onboarding} />

      <div className="flex-grow flex flex-col w-full mb-8 short:mb-16">
        <PageSlider
          className=""
          activeSlide={activeSlide}
          styling={{
            skipButton: !setting.onboarding,
            arrowButtons: true,
          }}
          slideChanged={(i: number) => setActiveSlide(i)}
        >
          {items.map((item, index) => (
            <OnboardingSlideItem
              key={index}
              className="h-full w-full"
              image={item.image}
              text={item.text}
              customComponent={item.customComponent}
              hideSkipButton={setting.onboarding}
              onSkip={__onSkip}
            />
          ))}
        </PageSlider>

        <div className="sm:hidden w-full flex-grow-0 flex justify-center mt-4">
          <Button
            text={t("start_a_measurement", "Start measurement")}
            type={ButtonType.Accent}
            textStyles={{ textTransform: "uppercase" }}
            className={cx("w-[200px] sm:w-[320px] sm:px-[32px] opacity-0 transition-opacity", {
              "opacity-100": activeSlide === items.length - 1,
            })}
            onClick={() => {
              localStorage.setItem("onboarding", Date.now().toString());
              dispatch(setOnboarding(false));
              if (setting.pluginSettings?.skip_welcome && setting.onboarding) {
                navigate("/measurement/do", { replace: true, state: { from: location.pathname } });
              } else {
                navigate(-1);
              }
            }}
          />
        </div>
      </div>
    </BaseLayout>
  );
};
